
<template>
    <div class="hero fullheight">
        <div class="hero-content">
            <div class="constraint">
                <h1>STAY UP TO DATE</h1>
                <div class="subheader">
                    <h2>Founded in 1992</h2>
                </div>
                <a href="#story">
                    <div class="button">Drink Coffee<img src="@/assets/arrow-right-up-354.svg" style="" alt=""></div>
                </a>
    
            </div>
        </div>
    </div>
    <div class="story" id="story">
        <div class="constraint">
            <div class="part1">
                <div class="half">
                    <h1>In 1992, Mocha Express was born</h1>
                    <p>Since then, we have stayed true to our core values. Its all about the "Amazing people, Amazing drinks, and Amazingly fast" experience we offer. It's a promise that stems from our humble beginnings and reflects our unwavering commitment
                        to providing an unforgettable experience to both the loyal and fresh coffee connoisseurs.</p>
                </div>
                <div class="half">
                    <div class="circle"> <img src="@/assets/cups-color.gif" alt=""></div>
                </div>
            </div>
        </div>
    </div>
</template>


<style lang="scss">
.fullheight {
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.hero-content {
    height: 100vh;
    position: absolute;
    width: 100vw;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    z-index: 2;
    max-width: 100%;
    margin: auto;
    padding: 4rem;
    .constraint {
        h1 {
            line-height: 1;
            font-weight: 600;
            margin-bottom: 1rem;
        }
        h2 {}
        .button {}
    }
    &:hover {}
}

.story {
    background: #000000;
    .health {
        background: #fcb134;
        width: 100%;
        padding: 4rem 0rem 4rem;
        .constraint {
            display: flex;
            flex-flow: wrap;
            justify-content:space-between;
            p {font-size:2rem;
                width:600px;
                margin-right: 5rem;
            }
        }
    }
    .coffee {
        background: white;
        width: 100%;
        padding: 4rem 0rem 4rem;
        p {
            padding: 0rem 0rem 4rem;
        }
        .constraint {
            .coffees {
                display: flex;
                flex-flow: wrap;
                justify-content: space-between;
                .coffee-stack {
                    display: flex;
                    flex-flow: column;
                    padding: 2rem;
                    li {
                        font-family: eurostile-std;
                        list-style: square;
                        margin-left: -40px;
                    }
                    img {
                        width: 200px;
                        margin-top: auto;
                    }
                    h2 {}
                }
            }
        }
    }
    .constraint {
        display: flex;
        flex-flow: column;
        .part1 {
            padding: 8rem 0rem;
            display: flex;
            flex-flow: wrap;
            justify-content: space-between;
            &:hover {
                .half {
                    .circle {                   

                        border: 10px solid #fcb134;
                        filter: saturate(1)contrast(1.5);
                        transform: rotate(0deg);
                    }
                    h1 {
                        color: #fcb134;
                        &:before {
                            background: #fcb134;
                        }
                        &:after {
                            background: #fcb134;
                        }
                    }
                }
            }
            .half {
                display: flex;
                flex-flow: column;
                justify-content: center;
                width: 500px;
                h1 {
                    margin-top: 6rem;
                    color: white;
                    line-height: 3rem;
                    padding-bottom: 2rem;
                    margin-bottom: 1.5rem;
                    transition: all 0.5s ease;
                    &:after {
                        transition: all 0.5s ease;
                        content: '';
                        position: absolute;
                        width: 120%;
                        height: 2px;
                        background: white;
                        bottom: 0px;
                        left: 0%;
                    }
                }
                p {
                    color: white;
                }
                .circle {
                    border: 10px solid white;
                    transform: rotate(0deg);
                    transition: all 0.5s ease;
                    img {
                        min-height: 100%;
                    }
                    width:500px;
                    height:500px;
                    overflow:hidden;
                    border-radius:100%;
                }
            }
        }
        .part2 {
            display: flex;
            flex-flow: row-reverse;
            justify-content: space-between;
            padding: 8rem 0rem;
            &:hover {
                .half {
                    .circle {
                        filter: saturate(1)contrast(1.5);
                        transform: rotate(0deg);
                    }
                }
            }
            .half {
                display: flex;
                flex-flow: column;
                justify-content: center;
                width: 500px;
                h1 {
                    margin-top: 12rem;
                    color: white;
                    line-height: 3rem;
                    padding-bottom: 2rem;
                    margin-bottom: 1.5rem;
                    text-align: right;
                    &:after {
                        content: '';
                        position: absolute;
                        width: 120%;
                        height: 2px;
                        background: white;
                        bottom: 0px;
                        right: 0%;
                    }
                }
                p {
                    color: white;
                    text-align: right;
                }
                .circle {
                    border: 10px solid white;
                    transform: rotate(0deg);
                    transition: all 0.5s ease;
                    img {
                        min-height: 100%;
                    }
                    width:500px;
                    height:500px;
                    overflow:hidden;
                    border-radius:100%;
                }
            }
        }
    }
}

.background-vid {
    height: auto;
    overflow: hidden;
    video {
        min-height: 100vh;
        min-width: 100vw;
        position: fixed;
    }
}

.constraint {
    margin: auto;
    text-align: left;
    width: 1080px;
    max-width: 100%;}

.subheader {
    margin: 1rem 0rem;
    margin-top: 0rem;
}

@media (max-width: 1024px) {
    .hero-content {
        height: 100vh;
        position: absolute;
        width: 100vw;
        display: flex;
        flex-flow: wrap;
        justify-content: flex-start;
        z-index: 2;
        max-width: 100%;
        margin: auto;
        padding: 0rem;
        .constraint {
            margin-top: 15vh;
            h1 {
                line-height: 1;
                font-weight: 600;
                margin-bottom: 1rem;
            }
            h2 {}
            .button {}
        }
        &:hover {}
    }
    .subheader {
        margin: 1rem 0rem;
        margin-top: 0rem;
    }
    .background-vid {
        display: none;
    }
.story {
    background: black;
    .health {
        background: #fcb134;
        width: 100%;
        padding: 4rem 0rem 4rem;
        .constraint {
            display: flex;
            flex-flow: wrap;
            p {font-size:1.2rem;
                margin: auto;width:auto;
                margin-right: 0rem;margin-bottom:2rem;
            }
            img{margin: 0rem auto 2rem;}
        }
    }
    .coffee {
        background: white;
        width: 100%;
        padding: 4rem 0rem 4rem;
        p {
            padding: 0rem 0rem 4rem;
        }
        .constraint {
            .coffees {
                display: flex;
                flex-flow: wrap;
                justify-content: space-between;
                .coffee-stack {
                    display: flex;
                    flex-flow: column;width:100%;
                    padding: 2rem;                       
ul{width:100%;}
                    li {
                        font-family: eurostile-std;
                        list-style: square;
                        margin-left: -40px;
                    }
                    img {
                        width: 200px;
                        margin-top: auto; margin:auto;
                    }
                    h2 {width:100%;}
                }
            }
        }
    }
        .constraint {
            padding: 2rem 2rem;
            .part1 {
                display: flex;
                flex-flow: column-reverse;
                justify-content: space-between;
                .half {
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    width: calc(100vw - 4rem);
                    h1 {
                        margin-top: 6rem;
                        color: white;
                        line-height: 2rem;
                        padding-bottom: 1rem;
                        margin-bottom: 0rem;
                        &:after {
                            content: '';
                            position: absolute;
                            width: 50%;
                            height: 2px;
                            background: white;
                            top: -1rem;
                            left: 0%;
                        }
                        &:before {
                            transition: all 0.5s ease;
                            content: '';
                            position: absolute;
                            width: 50%;
                            height: 2px;
                            transform-origin: right bottom;
                            transform: rotate(90deg);
                            background: white;
                            top: -1rem;
                            left: 0%;
                        }
                    }
                    p {
                        color: white;
                    }
                    .circle {
                        img {
                            min-height: 100%;
                        }
                        width:calc(100vw - 4rem);
                        height:calc(100vw - 4rem);
                        overflow:hidden;
                        border-radius:100%;
                    }
                }
            }
            .part2 {
                display: flex;
                flex-flow: column-reverse;
                justify-content: space-between;
                .half {
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    width: calc(100vw - 4rem);
                    h1 {
                        margin-top: 6rem;
                        color: white;
                        line-height: 2rem;
                        padding-bottom: 1rem;
                        margin-bottom: 0rem;
                        text-align: left;
                        &:after {
                            content: '';
                            position: absolute;
                            width: 50%;
                            height: 2px;
                            background: white;
                            top: -1rem;
                            left: 0%;
                        }
                        &:before {
                            transition: all 0.5s ease;
                            content: '';
                            position: absolute;
                            width: 50%;
                            height: 2px;
                            transform-origin: right bottom;
                            transform: rotate(90deg);
                            background: white;
                            top: -1rem;
                            left: 0%;
                        }
                    }
                    p {
                        color: white;
                        text-align: left;
                    }
                    .circle {
                        transform: rotate(0deg);
                        transition: all 0.5s ease;
                        img {
                            min-height: 100%;
                        }
                        width:calc(100vw - 4rem);
                        height:calc(100vw - 4rem);
                        overflow:hidden;
                        border-radius:100%;
                    }
                }
            }
        }
    }
}
</style>
