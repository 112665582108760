
<template>
    <div class="hero career-hero fullheight">
        <div class="hero-content">
            <div class="constraint">
                <h1>WORK WITH US</h1>
                <div class="subheader">
                    <h2>Join The Team!</h2>
                </div>
                <a href="#careers">
                    <div class="button">Get Started<img src="@/assets/arrow-right-up-354.svg" style="" alt=""></div>
                </a>
    
            </div>
        </div>
    </div>
    <div class="careers" id="careers">
        <div class="constraint">
            <div class="half">
                <h1>We are looking for the happiest people on earth</h1>
                <hr>
                <p>We offer a modern, pleasant workplace, competitive pay, and schedule flexability for a dynamic world.
    
                    <br><br>
                </p>
                <RouterLink class="" to="/contact">
                    <div class="button inverse">Contact Us<img src="@/assets/arrow-right-up-354.svg" style="" alt=""></div>
                </RouterLink>
            </div>
        </div>
    
    </div>
</template>


<style lang="scss">
.career-hero {
    background-size: contain !important;
    background-position: bottom right;
    background-image: url(../assets/coffee.jpg) !important;
}

.fullheight {
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.hero-content {
    height: 100vh;
    position: absolute;
    width: 100vw;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    z-index: 2;
    max-width: 100%;
    margin: auto;
    padding: 4rem;
    .constraint {
        h1 {
            line-height: 1;
            font-weight: 600;
            margin-bottom: 1rem;
        }
        h2 {}
        .button {}
    }
    &:hover {}
}

.careers {
    background: #000000;
    .constraint {
        display: flex;
        flex-flow: wrap-reverse;
        .half {
            max-width: 100%;
            &:nth-child(1) {}
            &:nth-child(2) {
                display: flex;
                width: 480px;
                max-width: 100%;
                margin: auto;
                img {
                    margin: auto;
                    width: 100%;
                }
            }
            h2 {
                color: white;
                font-size: 2rem;
                text-transform: capitalize;
            }
            h1 {
                color: white;
                text-transform: capitalize;
            }
            hr {
                margin: 2rem 0rem;
                height: 2px;
                background: white;
                width: 20%;
                border: unset;
                position: relative;
            }
            p {
                color: white;
            }
        }
    }
}

.constraint {
    margin: auto;
    text-align: left;
    width: 1080px;
    max-width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.subheader {
    margin: 1rem 0rem;
    margin-top: 0rem;
}

@media (max-width: 1024px) {
    .hero-content {
        height: 100vh;
        position: absolute;
        width: 100vw;
        display: flex;
        flex-flow: wrap;
        justify-content: flex-start;
        z-index: 2;
        max-width: 100%;
        margin: auto;
        padding: 0rem;
        .constraint {
            margin-top: 15vh;
            h1 {
                line-height: 1;
                font-weight: 600;
                margin-bottom: 1rem;
            }
            h2 {}
            .button {}
        }
        &:hover {}
    }
    .subheader {
        margin: 1rem 0rem;
        margin-top: 0rem;
    }
    .background-vid {
        display: none;
    }
    .story {
        background: black;
        .health {
            background: #fcb134;
            width: 100%;
            padding: 4rem 0rem 4rem;
            .constraint {
                display: flex;
                flex-flow: wrap;
                p {
                    font-size: 1.2rem;
                    margin: auto;
                    width: auto;
                    margin-right: 0rem;
                }
                img {
                    margin: 0rem auto 2rem;
                }
            }
        }
        .coffee {
            background: white;
            width: 100%;
            padding: 4rem 0rem 4rem;
            p {
                padding: 0rem 0rem 4rem;
            }
            .constraint {
                .coffees {
                    display: flex;
                    flex-flow: wrap;
                    justify-content: space-between;
                    .coffee-stack {
                        display: flex;
                        flex-flow: column;
                        width: 100%;
                        padding: 2rem;
                        ul {
                            width: 100%;
                        }
                        li {
                            font-family: eurostile-std;
                            list-style: square;
                            margin-left: -40px;
                        }
                        img {
                            width: 200px;
                            margin-top: auto;
                            margin: auto;
                        }
                        h2 {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .constraint {
            .part1 {
                display: flex;
                flex-flow: column-reverse;
                justify-content: space-between;
                .half {
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    width: calc(100vw - 4rem);
                    h1 {
                        margin-top: 6rem;
                        color: white;
                        line-height: 2rem;
                        padding-bottom: 1rem;
                        margin-bottom: 0rem;
                        &:after {
                            content: '';
                            position: absolute;
                            width: 50%;
                            height: 2px;
                            background: white;
                            top: -1rem;
                            left: 0%;
                        }
                        &:before {
                            transition: all 0.5s ease;
                            content: '';
                            position: absolute;
                            width: 50%;
                            height: 2px;
                            transform-origin: right bottom;
                            transform: rotate(90deg);
                            background: white;
                            top: -1rem;
                            left: 0%;
                        }
                    }
                    p {
                        color: white;
                    }
                    .circle {
                        img {
                            min-height: 100%;
                        }
                        width:calc(100vw - 4rem);
                        height:calc(100vw - 4rem);
                        overflow:hidden;
                        border-radius:100%;
                    }
                }
            }
            .part2 {
                display: flex;
                flex-flow: column-reverse;
                justify-content: space-between;
                .half {
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    width: calc(100vw - 4rem);
                    h1 {
                        margin-top: 6rem;
                        color: white;
                        line-height: 2rem;
                        padding-bottom: 1rem;
                        margin-bottom: 0rem;
                        text-align: left;
                        &:after {
                            content: '';
                            position: absolute;
                            width: 50%;
                            height: 2px;
                            background: white;
                            top: -1rem;
                            left: 0%;
                        }
                        &:before {
                            transition: all 0.5s ease;
                            content: '';
                            position: absolute;
                            width: 50%;
                            height: 2px;
                            transform-origin: right bottom;
                            transform: rotate(90deg);
                            background: white;
                            top: -1rem;
                            left: 0%;
                        }
                    }
                    p {
                        color: white;
                        text-align: left;
                    }
                    .circle {
                        transform: rotate(0deg);
                        transition: all 0.5s ease;
                        img {
                            min-height: 100%;
                        }
                        width:calc(100vw - 4rem);
                        height:calc(100vw - 4rem);
                        overflow:hidden;
                        border-radius:100%;
                    }
                }
            }
        }
    }
}
</style>
