
<template>
    <div class="hero contact-hero fullheight">
        <div class="constraint">
            <div class="form-content">
                <h2>CONTACT US</h2>
                <form action="https://formspree.io/f/xzbqqggk" method="POST">
                    <label>
                                                <input type="email" placeholder="EMAIL" name="email">
                                                </label>
                    <label>
                                                <textarea name="message" placeholder="MESSAGE"></textarea>
                                                </label>
                    <!-- your other form fields go here -->
                    <button type="submit">Send</button>
                </form>
            </div>
        </div>
    </div>
</template>


<style lang="scss">
.contact-hero {
    background-size: auto;
    background-position: 80% 80% ;
    background-image: url(../assets/blue-side.jpg) !important;
    background-size: 40vh !important;
}

.form-content {
    max-width: calc(100vw - 4rem);
    h2 {
        padding: 1rem;
        background: black;
        color: white;
        padding-bottom: 1rem;
    }
    form {
        width: 300px;
        max-width: 100%;
        padding: 1rem;
    }
    width: fit-content;
    display: flex;
    flex-flow: column;
    input {
        border-radius:unset!important;
        margin-bottom: 1rem;
        &::placeholder {
            opacity: 0.2;
            font-family: eurostile-bold;
        }
        padding: 0.5rem;
        font-size: 1rem;
        border: 2px solid black;
        font-family: eurostile-std;
    }
    textarea:focus,
    input:focus {
        outline: none;
    }
    textarea {
        border-radius:unset!important;
        &:focus {
            box-shadow: unset !important;
        }
        &::placeholder {
            opacity: 0.2;
            font-family: eurostile-bold;
        }
        padding: 0.5rem;
        font-size: 1rem;
        border: 2px solid black;
        font-family: eurostile-std;
    }
    label {
        display: flex;
        flex-flow: column;
        textarea {
            border: 2px solid black;
        }
    }
    button {
        z-index: 1;
        text-transform: uppercase;
        margin-top: 1rem;
        padding: 0.5rem 1rem;
        background: white;
        width: 100%;
        position: relative;
        cursor: pointer;
        font-family: eurostile-bold;
        border: 2px solid black;
        transition: all 0.2s ease;
        color:black;
        &:after {
            transition: all 0.2s ease;
            content: '';
            width: 00%;
            height: 100%;
            right: 0px;
            top: 0px;
            position: absolute;
            background: black;
            z-index: -1;
        }
        &:hover {
            &:after {
                content: '';
                width: 100%;
                height: 100%;
                left: 0px;
                top: 0px;
                position: absolute;
                background: black;
            }
            color:white;
            img {
                filter: invert(1);
                transform: translatey(-2px)translatex(2px)
            }
        }
    }
    background: rgb(255, 255, 255);
    border:2px solid black;
}

@media (max-width: 1024px) {
    .form-content {
        margin: auto;
        margin-top:unset;
    }
.contact-hero {
    background-size: auto;
    background-position:center bottom ;
    background-image: url(../assets/blue-side.jpg) !important;
    background-size: 25vh !important;
}

}
</style>
