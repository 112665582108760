
<template>
    <div class="hero hero-menu fullheight">
        <div class="hero-content">
            <div class="constraint">
                <h1>OUR DRINK SELECTION</h1>
                <div class="subheader">
                    <h2>Hot, Cold & More</h2>
                </div>
                <a href="#coffee">
                    <div class="button">Curated Menu<img src="@/assets/arrow-right-up-354.svg" style="" alt=""></div>
                </a>
    
            </div>
        </div>
    </div>
    <div class="coffee" id="coffee">
        <div class="constraint">
    
            <div class="coffees">
                <div class="coffee-stack classic">
                    <h2>Classics</h2>
                    <p>Mocha</p>
                    <p>Latte</p>
                    <p>Cappuccino</p>
                    <p>Americano</p>
                    <p>Espresso</p>
                    <p>House Coffee</p>
                    <p>Cold Brew</p>
                </div>
                <div class="coffee-stack specialty">
                    <h2>Specialty</h2>
                    <p>Snickers Mocha</p>
                    <p>White Gold</p>
                    <p>German Chocolate</p>
                    <p>Almond Joy Mocha</p>
                    <p>Eagle Vision Missile</p>
                    <p>Eagle Vision Buzz</p>
                    <p>Chai</p>
                    <p>Matcha</p>
                </div>
                <div class="coffee-stack frappe">
                    <h2>Blended Frappe</h2>
                    <p>Mocha Frappe</p>
                    <p>Snickers Frappe</p>
                    <p>Caramel Frappe</p>
                </div>
    
                <div class="coffee-stack energy">
                    <h2>Energy Drinks</h2>
                    <p>Regular</p>
                    <p>Infused</p>
                    <p>Sugar Free Infused</p>
                    <p>Organic Infused</p>
                </div>
    
                <div class="coffee-stack shakes">
                    <h2>Shakes</h2>
                    <p>Mocha Shake</p>
                    <p>Eagle Vision Shake</p>
                    <p>Peanut Butter Shake</p>
                    <p>Strawberry Shake</p>
                    <p>Matcha Shake</p>
                </div>
    
                <div class="coffee-stack fruit">
                    <h2>Real Fruit Smoothies</h2>
                    <p>Strawberry Bannana</p>
                    <p>Pineapple Strawberry</p>
                    <p>Peach Strawberry</p>
                    <p>Very Berry</p>
                    <p>Mango</p>
                </div>
    
                <div class="fullwidthcoffee">
                    <h2>Alternative Milks</h2>
                    <p>Oat Milk</p>
                    <p>Almond Milk</p>
                    <p>Coconut Milk</p>
                    <p>Soy Milk</p>
                </div>
    
                <div class="coffee-stack bubbletea2">
                    <h2>Bubble Tea (Fruit/Berry)</h2>
                    <p>Strawberry</p>
                    <p>Peach</p>
                    <p>Honeydew</p>
                    <p>Mango</p>
                    <p>Watermellon</p>
                    <p>Passion Fruit</p>
                </div>
                <div class="coffee-stack bubbletea1">
                    <h2>Bubble Tea (Milk Teas)</h2>
                    <p>Milk Tea</p>
                    <p>Matcha</p>
                    <p>Thai Tea</p>
                    <p>Taro Root</p>
                    <p>Lavendar</p>
                    <p>Coconut</p>
                    <p>Coffee</p>
                </div>
                <div class="coffee-stack snacks">
                    <h2>Pastries / Snacks</h2>
                    <p>Baklava</p>
                    <p>Cinnamon Rolls</p>
                    <p>Cookies</p>
                    <p>Bagels</p>
                    <p>Panini</p>
                </div>
    
                <div class="coffee-stack"><img src="@/assets/me-large.jpg" alt="">
                    <h2 class="drink-size">24 oz</h2>
                </div>
                <div class="coffee-stack"><img src="@/assets/me-med.jpg" alt="">
                    <h2 class="drink-size">20 oz</h2>
                </div>
                <div class="coffee-stack"><img src="@/assets/me-small.jpg" alt="">
                    <h2 class="drink-size">16 oz</h2>
                </div>
                <marquee behavior="scroll" direction="right">
                    <div class="marquee-1">
                        <div class="coffee-stack-img"><img src="@/assets/smoothie-2.jpg" alt="">
                        </div>
                        <div class="coffee-stack-img"><img src="@/assets/IMG_1366.jpg" alt="">
                        </div>
                        <div class="coffee-stack-img"><img src="@/assets/IMG_1370.jpg" alt="">
                        </div>
                        <div class="coffee-stack-img"><img src="@/assets/IMG_1369.jpg" alt="">
                        </div>
    
    
                        <div class="coffee-stack-img"><img src="@/assets/smoothie.jpg" alt="">
                        </div>
                        <div class="coffee-stack-img"><img src="@/assets/IMG_1388.jpg" alt="">
                        </div>
    
                        <div class="coffee-stack-img"><img src="@/assets/IMG_1389.jpg" alt="">
                        </div>
                        <div class="coffee-stack-img"><img src="@/assets/latte.jpg" alt="">
                        </div>
                        <div class="coffee-stack-img"><img src="@/assets/late-extra.jpg" alt="">
                        </div>
                        <div class="coffee-stack-img"><img src="@/assets/IMG_1373.jpg" alt="">
                        </div>
    
                        <div class="coffee-stack-img"><img src="@/assets/IMG_1368.jpg" alt="">
                        </div>
                        <div class="coffee-stack-img"><img src="@/assets/IMG_1367.jpg" alt="">
                        </div>
    
                        <div class="coffee-stack-img"><img src="@/assets/bluedrink.jpg" alt="">
                        </div>
                        <div class="coffee-stack-img"><img src="@/assets/energy.jpg" alt="">
                        </div>
                        <div class="coffee-stack-img"><img src="@/assets/baklava.jpg" alt="">
                        </div>
                    </div>
                </marquee>
            </div>
        </div>
    </div>
</template>


<style lang="scss">
marquee {
    display: flex;
    flex-flow: end;
    position: relative;
    min-height: 200px;
    padding: 10px;
    &:after {
        content: '';
        z-index: 1;
        width: 100%;
        left: 0px;
        top: 0px;
        height: 100%;
        position: absolute;
        box-shadow: inset 0px 0px 10px 20px white;
    }
}

.marquee-1 {
    display: flex;
    flex-flow: row;
}

.fruit {
    background-image: url(../assets/smoothie-2.jpg);
    background-size: 35%;
    background-repeat: no-repeat;
    background-position: 92% 92%;
}

.classic {
    background-image: url(../assets/latte.jpg);
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: 92% 70%;
}

.frappe {
    background-image: url(../assets/IMG_1368.jpg);
    background-size: 20%;
    background-repeat: no-repeat;
    background-position: 92% 70%;
}

.specialty {
    background-image: url(../assets/late-extra.jpg);
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: 92% 70%;
}

.shakes {
    background-image: url(../assets/IMG_1367.jpg);
    background-size: 22%;
    background-repeat: no-repeat;
    background-position: 92% 80%;
}

.energy {
    background-image: url(../assets/bluedrink.jpg);
    background-size: 25%;
    background-repeat: no-repeat;
    background-position: 92% 80%;
}

.bubbletea1 {
    background-image: url(../assets/IMG_1369.jpg);
    background-size: 25%;
    background-repeat: no-repeat;
    background-position: 92% 80%;
}

.bubbletea2 {
    background-image: url(../assets/IMG_1370.jpg);
    background-size: 25%;
    background-repeat: no-repeat;
    background-position: 92% 80%;
}

.snacks {
    background-image: url(../assets/baklava.jpg);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: 92% 80%;
}

.hero-menu {
    background: url(../assets/energy.jpg) !important;
    background-size: 50vh !important;
    background-repeat: no-repeat !important;
    background-position: 90% 90% !important;
}

.coffee {
    background: white;
    width: 100%;
    padding: 4rem 0rem 4rem;
    p {
        padding: 0rem 0rem 4rem;
    }
    .constraint {
        .coffees {
            padding: 0px;
            display: flex;
            flex-flow: wrap;
            justify-content: center;
            .fullwidthcoffee {
                width: 100%;
                background: black;
                padding: 2rem;
                color: white;
                margin: 1rem;
                p {
                    font-size: 1.5rem;
                    color: white;
                    padding: unset;
                }
                h2 {
                    font-size: 2rem;
                    margin-bottom: 1rem;
                }
            }
            .coffee-stack {
                color: #000000;
                width: 508px;
                height: auto;
                margin: 1rem;
                display: flex;
                flex-flow: column;
                padding: 2rem;
                border: 2px solid #00000008;
                li {
                    font-family: eurostile-std;
                    list-style: square;
                    margin-left: -40px;
                }
                img {
                    width: 100px;
                    max-height: 100%;
                    margin: auto;
                    margin-bottom: unset;
                }
                .drink-size {
                    text-align: right;
                    margin-bottom: 0rem;
                }
                h2 {
                    font-size: 2rem;
                    margin-bottom: 1rem;
                }
                p {
                    font-size: 1.2rem;
                    color: rgb(0, 0, 0);
                    padding: unset;
                }
            }
            .coffee-stack-img {
                background: transparent;
                color: white;
                width: 325px;
                height: 325px;
                margin: 10px;
                display: flex;
                flex-flow: column;
                padding: 2rem;
                li {
                    font-family: eurostile-std;
                    list-style: square;
                    margin-left: -40px;
                }
                img {
                    width: auto;
                    max-height: 100%;
                    margin: auto;
                    max-width: 100%;
                }
                .drink-size {
                    text-align: right;
                    margin-bottom: 0rem;
                }
                h2 {
                    font-size: 1.2rem;
                    margin-bottom: 1rem;
                }
                p {
                    font-size: 1rem;
                    color: white;
                    padding: unset;
                }
            }
        }
    }
}

.fullheight {
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.hero-content {
    height: 100vh;
    position: absolute;
    width: 100vw;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    z-index: 2;
    max-width: 100%;
    margin: auto;
    padding: 4rem;
    .constraint {
        h1 {
            line-height: 1;
            font-weight: 600;
            margin-bottom: 1rem;
        }
        h2 {}
        .button {}
    }
    &:hover {}
}

.story {
    background: #000000;
    .health {
        background: #fcb134;
        width: 100%;
        padding: 4rem 0rem 4rem;
        .constraint {
            display: flex;
            flex-flow: wrap;
            justify-content: space-between;
            p {
                font-size: 2rem;
                width: 600px;
                margin-right: 5rem;
            }
        }
    }
    .coffee {
        background: white;
        width: 100%;
        padding: 4rem 0rem 4rem;
        p {
            padding: 0rem 0rem 4rem;
        }
        .constraint {
            .coffees {
                display: flex;
                flex-flow: wrap;
                justify-content: space-between;
                .coffee-stack {
                    display: flex;
                    flex-flow: column;
                    padding: 2rem;
                    li {
                        font-family: eurostile-std;
                        list-style: square;
                        margin-left: -40px;
                    }
                    img {
                        width: 200px;
                        margin-top: auto;
                    }
                    h2 {}
                }
            }
        }
    }
    .constraint {
        display: flex;
        flex-flow: column;
        padding: 2rem 2rem;
        .part1 {
            padding: 8rem 0rem;
            display: flex;
            flex-flow: wrap;
            justify-content: space-between;
            &:hover {
                .half {
                    .circle {
                        border: 10px solid #fcb134;
                        filter: saturate(1)contrast(1.5);
                        transform: rotate(0deg);
                    }
                    h1 {
                        color: #fcb134;
                        &:before {
                            background: #fcb134;
                        }
                        &:after {
                            background: #fcb134;
                        }
                    }
                }
            }
            .half {
                display: flex;
                flex-flow: column;
                justify-content: center;
                width: 500px;
                h1 {
                    margin-top: 6rem;
                    color: white;
                    line-height: 3rem;
                    padding-bottom: 2rem;
                    margin-bottom: 1.5rem;
                    transition: all 0.5s ease;
                    &:after {
                        transition: all 0.5s ease;
                        content: '';
                        position: absolute;
                        width: 120%;
                        height: 2px;
                        background: white;
                        bottom: 0px;
                        left: 0%;
                    }
                }
                p {
                    color: white;
                }
                .circle {
                    border: 10px solid white;
                    transform: rotate(0deg);
                    transition: all 0.5s ease;
                    img {
                        min-height: 100%;
                    }
                    width:500px;
                    height:500px;
                    overflow:hidden;
                    border-radius:100%;
                }
            }
        }
        .part2 {
            display: flex;
            flex-flow: row-reverse;
            justify-content: space-between;
            padding: 8rem 0rem;
            &:hover {
                .half {
                    .circle {
                        filter: saturate(1)contrast(1.5);
                        transform: rotate(0deg);
                    }
                }
            }
            .half {
                display: flex;
                flex-flow: column;
                justify-content: center;
                width: 500px;
                h1 {
                    margin-top: 12rem;
                    color: white;
                    line-height: 3rem;
                    padding-bottom: 2rem;
                    margin-bottom: 1.5rem;
                    text-align: right;
                    &:after {
                        content: '';
                        position: absolute;
                        width: 120%;
                        height: 2px;
                        background: white;
                        bottom: 0px;
                        right: 0%;
                    }
                }
                p {
                    color: white;
                    text-align: right;
                }
                .circle {
                    border: 10px solid white;
                    transform: rotate(0deg);
                    transition: all 0.5s ease;
                    img {
                        min-height: 100%;
                    }
                    width:500px;
                    height:500px;
                    overflow:hidden;
                    border-radius:100%;
                }
            }
        }
    }
}

.background-vid {
    height: auto;
    overflow: hidden;
    video {
        min-height: 100vh;
        min-width: 100vw;
        position: fixed;
    }
}

.constraint {
    margin: auto;
    text-align: left;
    width: 1080px;
    max-width: 100%;
}

.subheader {
    margin: 1rem 0rem;
    margin-top: 0rem;
}

@media (max-width: 1024px) {
    .hero-content {
        height: 100vh;
        position: absolute;
        width: 100vw;
        display: flex;
        flex-flow: wrap;
        justify-content: flex-start;
        z-index: 2;
        max-width: 100%;
        margin: auto;
        padding: 0rem;
        .constraint {
            margin-top: 15vh;
            h1 {
                line-height: 1;
                font-weight: 600;
                margin-bottom: 1rem;
            }
            h2 {}
            .button {}
        }
        &:hover {}
    }
    .subheader {
        margin: 1rem 0rem;
        margin-top: 0rem;
    }
    .background-vid {
        display: none;
    }
    .coffee {
        background: white;
        width: 100%;
        padding: 4rem 0rem 4rem;
        p {
            padding: 0rem 0rem 4rem;
        }
        .constraint {
            .coffees {
                padding: 0px;
                display: flex;
                flex-flow: wrap;
                justify-content: center;
                .fullwidthcoffee {
                    width: calc(100vw - 4rem);
                    padding: 2rem;
                    margin: unset;
                    margin-bottom: 2rem;
                    p {
                        font-size: 1rem;
                        padding: unset;
                    }
                    h2 {
                        font-size: 2rem;
                        margin-bottom: 1rem;
                    }
                }
                .coffee-stack-img {
                    background: transparent;
                    color: white;
                    width: 200px;
                    height: 300px;
                    margin: unset;
                    display: flex;
                    flex-flow: column;
                    padding: 2rem;
                    li {
                        font-family: eurostile-std;
                        list-style: square;
                        margin-left: -40px;
                    }
                    img {
                        width: 100%;
                        max-height: 100%;
                        max-width: 100%;
                        height: auto;
                        margin: auto;
                        margin-bottom: unset;
                    }
                    .drink-size {
                        text-align: right;
                        margin-bottom: 0rem;
                    }
                    h2 {
                        font-size: 2rem;
                        margin-bottom: 1rem;
                    }
                    p {
                        font-size: 1rem;
                        color: white;
                        padding: unset;
                    }
                }
                .coffee-stack {
                    width: calc(100vw - 4rem);
                    min-height: calc(50vw - 4rem);
                    height: auto;
                    margin: unset;
                    margin-bottom: 2rem;
                    margin-top: 0rem;
                    display: flex;
                    flex-flow: column;
                    padding-bottom: 5rem;
                    li {
                        font-family: eurostile-std;
                        list-style: square;
                        margin-left: -40px;
                    }
                    img {
                        width: auto;
                        max-width: 100%;
                        height: auto;
                        margin: auto;
                    }
                    h2 {}
                }
            }
        }
    }
    .hero-menu {
        background-size: 30vh !important;
        background-position: center 90% !important;
    }
}
</style>
