<script>
import './assets/main.css'
export default {

    methods: {
        scrollToTop() {
            document.querySelector("#app").scrollTo(0, 0);
        }
    },
    data() {
        return {
            naved: false,
        }
    },
    components: {},
}
</script>

<template>
    <header>
        <div class="constraint">
    
            <RouterLink to="/">
                <div class="title-wrap">
                    <img alt="Mocha Express Logo" class="logo" src="@/assets/melogo.svg" />
                    <h1>MOCHA EXPRESS</h1>
    
                </div>
            </RouterLink>
            <div class="menu-items-desktop">
                <RouterLink @click="scrollToTop()" class="menu-item-desktop" to="/menu">Menu</RouterLink>
                <RouterLink @click="scrollToTop()" class="menu-item-desktop" to="/career">Careers</RouterLink>
                <RouterLink @click="scrollToTop()" class="menu-item-desktop" to="/invest">Investors</RouterLink>
                <RouterLink @click="scrollToTop()" class="menu-item-desktop" to="/contact">Contact</RouterLink>
            </div>
            <svg @click="naved = !naved" class="menu ham hamRotate ham8" viewBox="0 0 100 100" width="80" :class="{ active: naved }">
                            <path
                                class="line top"
                                d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
                            <path
                                class="line middle"
                                d="m 30,50 h 40" />
                            <path
                                class="line bottom"
                                d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
                        </svg>
    
            <nav :class="{ menuactive: naved }" @click="naved = !naved">
                <RouterLink @click="scrollToTop()" class=" menu-item" to="/menu">Menu</RouterLink>
                <RouterLink @click="scrollToTop()" class=" menu-item" to="/career">Careers</RouterLink>
                <RouterLink @click="scrollToTop()" class=" menu-item" to="/invest">Investors</RouterLink>
                <RouterLink @click="scrollToTop()" class=" menu-item" to="/contact">Contact</RouterLink>
            </nav>
        </div>
    
    </header>
    <div class="lightbox-base" :class="{ lightbox: naved }" @click="naved = false"> </div>
    <RouterView />
    <footer>
        <div class="constraint">
            <div class="icons" style="display:none!important;">
                <a target="_blank" href="https://www.instagram.com/mochaexpresscoffee/">INSTAGRAM</a>
            </div>
            <p style="color:white;font-size:1rem;font-weight:600;">MOCHA EXPRESS © 2023</p>
        </div>
    </footer>
</template>

<style lang="scss">
.active {
    transform: rotate(135deg);
}

.hamRotate.active {
    transform: rotate(45deg);
}

.hamRotate180.active {
    transform: rotate(0deg);
}

.lightbox {
    width: calc(100vw - 200px) !important;
    background: #0008 !important;
    transition: all 0.2s ease;
    left: 0vw !important;
}

.lightbox-base {
    position: fixed;
    z-index: 5;
    width: 100vw;
    transition: all 0.2s ease;
    left: 100vw;
    height: 100vh;
    background: #0005;
}

.mobile {
    display: none;
}

.disabled {
    pointer-events: none;
    opacity: 0.2;
}

.line {
    fill: none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: black;
    stroke-width: 5.5;
    stroke-linecap: round;
}

.ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ham8 .top {
    stroke-dasharray: 40 160;
}

.ham8 .middle {
    stroke-dasharray: 40 142;
    transform-origin: 50%;
    transition: transform 400ms;
}

.ham8 .bottom {
    stroke-dasharray: 40 85;
    transform-origin: 50%;
    transition: transform 400ms, stroke-dashoffset 400ms;
}

.ham8.active .top {
    stroke-dashoffset: -64px;
}

.ham8.active .middle {
    //stroke-dashoffset: -20px;
    transform: rotate(90deg);
}

.ham8.active .bottom {
    stroke-dashoffset: -64px;
}

header {
    z-index: 5;
    line-height: 1.5;
    top: 0px;
    position: fixed;
    display: flex;
    flex-flow: wrap;
    width: 100vw;
    overflow: hidden;
    justify-content: center;
    z-index: 200;
    background: white;
    .constraint {
        justify-content: space-between;
        display: flex;
        flex-flow: wrap;padding:unset!important;

    }
}

.title-wrap {
    display: flex;
    flex-flow: row;
    h1 {
        color: black;
        font-size: 1rem;
        margin: auto;
        margin-left: 1rem;
        font-weight: bold;
    }
    .logo {
        transition: all 0.2s ease;
    }
    &:hover {
        .logo {
            transform: scale(1.1);
        }
    }
}

.logo {
    margin: 10px 0px;
    width: 80px;
    height: 80px;
}

.menu {
    margin: 0px;
    width: 50px;
    height: 100px;
    position: fixed;
    right: 20px;
    cursor: pointer;
    z-index: 200;
}

nav {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 200px;
    height: 100vh;
    position: fixed;
    background: black;
    transition: all 0.2s ease;
    top: 0px;
    right: -200px;
}

.menu-item {
    transition: all 0.5s ease;
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    height: 4rem;
    padding-right: 1rem;
    text-align: right;
    line-height: 4rem;
    color: white;
    &:after {
        transition: all 0.2s ease;
        content: '';
        width: 00%;
        height: 100%;
        left: 0px;
        top: 0px;
        position: absolute;
        background: white;
        z-index: -1;
    }
    &:hover {
        color: black;
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            right: 0px;
            left: unset;
            top: 0px;
            position: absolute;
            background: white;
        }
    }
}

.menu-items-desktop {
    display: flex;
    flex-flow: row;
    height: auto;
}

.menu-item-desktop {
    transition: all 0.5s ease;
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    text-align: right;
    line-height: 1;
    display: flex;
    height: min-content;
    position: relative;
    margin: auto 1rem;
    color: black !important;
    &:after {
        content: '';
        width: 0px;
        right: 0px;
        height: 2px;
        background: white;
        bottom: -4px;
        position: absolute;
        transition: all 0.2s ease;
    }
    &:hover {
        &:after {
            right: unset;
            left: 0px;
            width: 100%;
        }
    }
}

.menuactive {
    right: 0px;
}

footer {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    padding: 50px;
    background: black;
    .constraint {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
    }
}

.icons {
    display: flex;
    color: white;
    a {
        margin-right: 1rem;
        color: white;
        font-weight: 600;
        font-size: 1rem;
    }
}

@keyframes slidein {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

.button {
    font-size: 1rem;
    font-weight: 700;
    min-width: 10rem;
    width: max-content;
    padding: 0rem 2rem;
    height: 4rem;
    color: black;
    display: flex;
    text-align: center;
    flex-flow: column;
    justify-content: center;
    border: 2px solid black;
    transition: all 0.2s ease;
    text-transform: uppercase;
    &:after {
        transition: all 0.2s ease;
        content: '';
        width: 00%;
        height: 100%;
        right: 0px;
        top: 0px;
        position: absolute;
        background: black;
        z-index: -1;
    }
    &:hover {
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
            position: absolute;
            background: black;
        }
        color:white !important;
        img {
            filter: invert(1);
            transform: translatey(-2px)translatex(2px)
        }
    }
    img {
        display: none;
        margin: auto 0rem;
        transition: all 0.2s ease;
        margin-left: 1rem;
        height: 24px;
    }
}

a {
    &:hover {
        p {
            opacity: 0.5;
        }
        h1 {
            opacity: 0.5;
        }
        img {
            opacity: 1;
        }
        .button {
            opacity: 1;
        }
    }
}

@media (max-width: 1024px) {
    .menu-items-desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
    .button {
        display: none;
        font-size: 16px;
        display: flex;
        padding: 0.5rem 1rem;
        width: max-content;
        transition: all 0.2s ease;
        position: relative;
        &:hover {
            img {
                filter: invert(1);
                transform: translatey(-2px)translatex(2px)
            }
        }
        img {
            margin: auto 0rem;
            transition: all 0.2s ease;
            margin-left: 1rem;
            height: 16px;
        }
    }
    .title-wrap {
        h1 {
            display: none;
        }
    }
    .logo {
        width: 50px;
        height: 50px;
        margin-top: 12.5px;
        margin-left: 12.5px;
    }
    .menu {
        width: 50px;
        height: 75px;
    }
    footer {
        padding: 25px;
        background: black;
        justify-content: center;
        .constraint {
            flex-flow: column;
            text-align: center;
            justify-content: center;
        }
    }
    .icons {
        margin-bottom: 1rem;
        display: flex;
        flex-flow: row;
        justify-content: center;
        a {
            color: white;
            margin-right: 1rem;
            margin-left: 1rem;
            font-weight: 600;
        }
    }
    header {
        z-index: 5;
        line-height: 1.5;
        top: 0px;
        position: fixed;
        display: flex;
        flex-flow: wrap;
        width: 100vw;
        overflow: hidden;
        justify-content: center;
        .constraint {
            justify-content: flex-start;
            display: flex;
            flex-flow: wrap;
        }
    }
}
</style>
